import React from "react";
import "./style.less";
import { Row, Col } from "antd";
import Link from "../Link";
import ReactHtmlParser from "react-html-parser";

class StorytellersLarge extends React.Component {
  render() {
    return (
      <div
        className="storytellers-large"
        style={{
          backgroundImage:
            "url('" +
            this.props.content.background_image.localFile.childImageSharp
              .original.src +
            "')",
        }}
      >
        <Row>
          <Col xs={{ span: 24 }}>{this.props.title}</Col>
          <Col
            xs={{ span: 24 }}
            md={{ span: 12, offset: 12 }}
            lg={{ span: 10, offset: 14 }}
          >
            <p className="story-lead">
              {ReactHtmlParser(this.props.content.heading)}
            </p>
            <p>{ReactHtmlParser(this.props.content.blurb)}</p>
            <Link to={this.props.content.cta_link} className="btn-primary">
              {this.props.content.cta_text}
            </Link>
          </Col>
        </Row>
      </div>
    );
  }
}

export default StorytellersLarge;
