import React from 'react'
import './style.less';
import {Carousel} from 'antd';
import Img from "gatsby-image";

class Logos extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            slides: null,
        };
    }

    render(){

        const settings = {
            dots: true,
            slidesToShow: 6,
            slidesToScroll: 1,
            centerMode: true,
            autoplay: true,
            autoplaySpeed: 0,
            speed: 5000,
            cssEase: "linear",
            swipeToSlide: true,
            responsive: [
                {
                    breakpoint: 1360,
                    settings: {
                        slidesToShow: 5
                    }
                },{
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 4
                    }
                },{
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 3
                    }
                },{
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2
                    }
                },{
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        };

        return (
            <Carousel className="logo-slider" {...settings}>
                {this.props.logos && this.props.logos.map((logo) => {
                    return <div className="slide" key={logo.id}>
                        { logo != null ?
                            <Img fluid={logo.localFile.childImageSharp.fluid} />
                        : null }
                    </div>
                })}
            </Carousel>
        );
    }
}

export default Logos